var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[(_vm.showTopImg)?_c('div',{staticClass:"top-image bg-center p-relative bg-no-repeat bg-size-cover",style:({
            backgroundImage: `url(${_vm.topImg})`
        })},[_c('span',{staticClass:"font-600 p-absolute top-text",class:`top${_vm.index+1}`},[_vm._v("TOP"+_vm._s(_vm.index + 1))])]):_vm._e(),_c('div',{staticClass:"w-100 overflow-hidden p-relative"},[_c('div',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"live-cover p-relative pointer bg-center bg-no-repeat transition-3",class:{
              'bg-size-cover': _vm.hasCover,
              'bg-size-auto': !_vm.hasCover
           }},[_c('img',{staticClass:"w-100 h-100",attrs:{"data-src":_vm.cover,"data-error":_vm.cardEmptyLogo,"alt":""}}),_c('div',{staticClass:"mask w-100 h-100 pointer p-absolute",style:(_vm.maskBg),on:{"click":_vm.goLiveRoom}})]),_c('div',{staticClass:"live-icon p-absolute"},[_c('DynamicDot',{attrs:{"outer-bg-color":'#fff',"inner-bg-color":'#fff'}}),_vm._v("直播中 ")],1)]),_c('div',{staticClass:"host flex flex-column align-center p-t-5 p-b-5 p-l-10 p-r-15",class:{
            'bg-white': !_vm.bgGray,
            'bg-gray2': _vm.bgGray
        }},[_c('custom-span',{staticClass:"font-14 w-100 font-500 text-5e",attrs:{"content":_vm.info.room_title}}),_c('div',{staticClass:"title w-100 m-t-5 flex align-center justify-between font-medium m-l-5"},[_c('div',{staticClass:"user-info flex align-center"},[_c('span',{directives:[{name:"lazy-container",rawName:"v-lazy-container",value:({ selector: 'img' }),expression:"{ selector: 'img' }"}],staticClass:"avatar d-inline-block overflow-hidden border-radius-50"},[_c('img',{staticClass:"w-100 h-100",attrs:{"data-src":_vm.info.img,"data-error":_vm.errorUserLogo,"alt":""}})]),_c('span',{staticClass:"name scale-9 d-inline-block m-l-5"},[_c('custom-span',{staticClass:"w-100 font-12",attrs:{"content":_vm.info.nick || _vm.info.anchor_name}})],1)]),_c('div',{staticClass:"views flex align-center flex-end"},[_c('span',{staticClass:"iconfont icon default font-14"},[_vm._v("")]),_c('span',{staticClass:"m-l-5 font-12"},[_vm._v(_vm._s(_vm.heatNum))])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }