<template>
<div class="box p-relative">
  <div class="inner dot-inner-animate p-absolute" :style="{backgroundColor:innerBgColor}"></div>
  <div class="outer dot-outer-animate" :style="{backgroundColor:outerBgColor}"></div>
</div>
</template>

<script>
export default {
    name: 'DynamicDot',
    props: {
        outerBgColor: {
            type: String,
            default: '#f8474f'
        },
        innerBgColor: {
            type: String,
            default: '#F7464E'
        }
    }
}
</script>

<style lang="scss" scoped>
.box {
  width: 12px;
  height: 12px;
}
.outer {
  width: 12px;
  height: 12px;
  opacity: 0.5;
  border-radius: 12px;
}
.inner {
  width: 6px;
  height: 6px;
  left: 3px;
  top: 3px;
  border-radius: 3px;
}
</style>
