var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"lineupBox",staticClass:"lineup-box p-relative w-100"},[(_vm.competitionData)?[_c('div',{staticClass:"list",class:{
          'is-live-room': _vm.isLiveRoom
        }},[(_vm.competitionData)?_c('div',{staticClass:"row flex justify-between align-center"},[_c('div',{staticClass:"left item p-t-15 p-b-15 flex align-center justify-between"},[[_c('div',{staticClass:"flex align-center"},[_c('span',{staticClass:"team-icon icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                          backgroundImage: `url(${_vm.homeLogo})`
                      })}),_c('div',{staticClass:"player p-l-15 flex flex-column text-left"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.competitionData.homeNameChs))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v("主教练: "+_vm._s(_vm.competitionData.homeCoachCn))])])])]],2),_c('div',{staticClass:"right p-t-15 p-b-15 item flex align-center flex-end"},[[_c('div',{staticClass:"flex align-center"},[_c('div',{staticClass:"player p-r-15 flex flex-column text-right"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.competitionData.awayNameChs))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v(_vm._s(_vm.competitionData.awayCoachCn)+": 主教练")])]),_c('span',{staticClass:"team-icon icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                          backgroundImage: `url(${_vm.awayLogo})`
                      })})])]],2)]):_vm._e(),(_vm.competitionData)?[_vm._l((5),function(i,index){return [_c('div',{key:`${index}-lineup`,staticClass:"row flex justify-between align-center"},[_c('div',{staticClass:"left p-b-15 p-t-15 item flex align-center justify-between",class:{
                    'border-none': !_vm.homePlayers[index]
                }},[(_vm.homePlayers[index])?[_c('div',{staticClass:"flex align-center"},[_c('span',{staticClass:"icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                                backgroundImage: `url(${_vm.homePlayers[index].photo})`
                              })}),_c('div',{staticClass:"player p-l-15 flex flex-column text-left"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.homePlayers[index] ? _vm.homePlayers[index].nameChs : ''))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v(_vm._s(_vm.homePlayers[index].nameEn))])])]),_c('div',{staticClass:"flex flex-column align-center"},[_c('span',{staticClass:"font-14 text-333"},[_vm._v(_vm._s(_vm.homePlayers[index].number))]),_c('span',{staticClass:"font-12 m-t-5 text-999"},[_vm._v(_vm._s(_vm.homePlayers[index].positionCn))])])]:_vm._e()],2),_c('div',{staticClass:"right p-b-15 p-t-15 item flex align-center justify-between"},[(_vm.awayPlayers[index])?[_c('div',{staticClass:"flex flex-column align-center"},[_c('span',{staticClass:"font-14 text-333"},[_vm._v(_vm._s(_vm.awayPlayers[index].number))]),_c('span',{staticClass:"font-12 m-t-5 text-999"},[_vm._v(_vm._s(_vm.awayPlayers[index].positionCn))])]),_c('div',{staticClass:"flex align-center"},[_c('div',{staticClass:"player p-r-15 flex flex-column text-right"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.awayPlayers[index] ? _vm.awayPlayers[index].nameChs : ''))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v(_vm._s(_vm.awayPlayers[index].nameEn))])]),_c('span',{staticClass:"icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                          backgroundImage: `url(${_vm.awayPlayers[index].photo})`
                        })})])]:_vm._e()],2)])]})]:_vm._e(),_vm._m(0),_vm._l((_vm.backupMaxCount),function(i,index){return [_c('div',{key:i,staticClass:"row flex justify-between align-center"},[_c('div',{staticClass:"left p-b-15 p-t-15 item flex align-center justify-between",class:{
                        'border-none': !_vm.homeBackups[index]
                    }},[(_vm.homeBackups[index])?[_c('div',{staticClass:"flex align-center"},[_c('span',{staticClass:"icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                              backgroundImage: `url(${_vm.homeBackups[index].photo})`
                            })}),_c('div',{staticClass:"player p-l-15 flex flex-column text-left"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.homeBackups[index] ? _vm.homeBackups[index].nameChs : ''))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v(_vm._s(_vm.homeBackups[index].nameEn))])])]),_c('div',{staticClass:"flex flex-column align-center"},[_c('span',{staticClass:"font-14 text-333"},[_vm._v(_vm._s(_vm.homeBackups[index].number))]),_c('span',{staticClass:"font-12 m-t-5 text-999"},[_vm._v(_vm._s(_vm.homeBackups[index].positionCn))])])]:_vm._e()],2),_c('div',{staticClass:"right p-b-15 p-t-15 item flex align-center justify-between",class:{
                        'border-none': !_vm.awayBackups[index]
                    }},[(_vm.awayBackups[index])?[_c('div',{staticClass:"flex flex-column align-center"},[_c('span',{staticClass:"font-14 text-333"},[_vm._v(_vm._s(_vm.awayBackups[index].number))]),_c('span',{staticClass:"font-12 m-t-5 text-999"},[_vm._v(_vm._s(_vm.awayBackups[index].positionCn))])]),_c('div',{staticClass:"flex align-center"},[_c('div',{staticClass:"player p-r-15 flex flex-column text-right"},[_c('span',{staticClass:"font-medium font-14 text-333"},[_vm._v(_vm._s(_vm.awayBackups[index] ? _vm.awayBackups[index].nameChs : ''))]),_c('span',{staticClass:"font-12 m-t-5 font-regular text-999"},[_vm._v(_vm._s(_vm.awayBackups[index].nameEn))])]),_c('span',{staticClass:"icon d-inline-block bg-center bg-no-repeat bg-size-cover",style:({
                              backgroundImage: `url(${_vm.awayBackups[index].photo})`
                            })})])]:_vm._e()],2)])]})],2),_c('div',{staticClass:"player-box p-absolute bg-size-100 bg-center bg-no-repeat"},[_vm._l((_vm.homePlayers),function(player){return [_c('Member',{key:player.playerId,attrs:{"member":player,"is-home":""}})]}),_vm._l((_vm.awayPlayers),function(player){return [_c('Member',{key:'away' + player.playerId,attrs:{"member":player}})]})],2)]:_vm._e(),(!_vm.loading && !_vm.competitionData)?_c('no-data'):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row m-t-25 m-b-20 flex justify-between align-center"},[_c('span',{staticClass:"left-backup p-l-10 font-14 text-333"},[_vm._v("替补名单")]),_c('span',{staticClass:"right-backup p-r-10 font-14 text-333"},[_vm._v("替补名单")])])
}]

export { render, staticRenderFns }