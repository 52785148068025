<template>
<span class="icon d-inline-block pointer">

</span>
</template>

<script>
export default {
    name: 'VirtualIcon'
}
</script>

<style lang="scss" scoped>
@import '@/theme/default-vars.scss';
.icon {
    width: 27.5px;
    height: 19px;
    background-image: url('../assets/images/score/football-bg.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}

</style>
