<template>
  <TransitionGroup tag="ul" name="fade" class="new-entry" :style="{ bottom }">
    <li v-for="item in entryQueue" class="item" :key="item.nickname">
        <div class="msg_box">
                <div class="d-inline-block">
                    <div class="flex align-center">
                            <span
                                class="member_level"
                                :class="`label${item.label}`"
                            >
                        </span>
                        <span class="nickname msg_title m-l-10">{{ item.nickname }}：</span>
                    </div>
                </div>
                <span class="p_come_msg">{{ item.msg }}</span>
        </div>
    </li>
  </TransitionGroup>
</template>

<script>
export default {
    props: {
        bottom: {
            type: String
        }
    },
    data () {
        return {
            entryQueue: []
        }
    },
    mounted () {
        // this.interval = setInterval(() => {
        //     if (this.entryQueue.length > 0) {
        //         this.entryQueue.splice(0, 1)
        //     }
        // }, 3000)
    },
    beforeDestroy () {
        clearInterval(this.interval)
    },
    methods: {
        addEntry (item) {
            console.log(item, 'item')
            this.entryQueue.splice(this.entryQueue.length, 0, item)
            // 3 秒后，移除第一个
            setTimeout(() => {
                this.entryQueue.splice(0, 1)
            }, 3000)
        }
    }
}
</script>

<style lang="scss" scoped>
.new-entry {
    width: 100%;
    height: 30px;
    overflow: hidden;
    position: absolute;
    bottom: 80px;
    left: 0;
    padding: 0;
    list-style-type: none;
    color: #fff;
    background: transparent;
    z-index: 11;
}

.item {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  color: #fff;
  background: #fff;
}

/* 1. 声明过渡效果 */
.fade-move,
.fade-enter-active,
.fade-leave-active {
  transition: all 0.5s cubic-bezier(0.55, 0, 0.1, 1);
}

/* 2. 声明进入和离开的状态 */
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: scaleY(0.01) translate(30px, 0);
}

/* 3. 确保离开的项目被移除出了布局流
      以便正确地计算移动时的动画效果。 */
.fade-leave-active {
  position: absolute;
}

.msg_box {
    width: 90%;
    margin: auto;
    color: #4164FF;
    word-break: break-all;
    display: flex;
    font-size: 14px;

    .member_level {
        display: inline-block;
        width: 46px;
        height: 26px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: 100%;
        &.label1 {
        background-image: url('../../../assets/images/gift/member.png');
        }
        &.label11 {
        background-image: url('../../../assets/images/gift/host.png');
        }
        &.label12 {
        background-image: url('../../../assets/images/gift/expert.png');
        }
        &.label0 {
        background-image: url('../../../assets/images/gift/tourist.png');
        }
    }

    .msg_title {
        font-weight: 600;
        color: var(--dq-text-main);
    }

    .nickname {
        color: var(--dq-text-main);
    }

    .p_come_msg {
        color: #989cb3;
    line-height: 26px;
    }
}
</style>
