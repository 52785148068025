var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper-box w-100"},[[_c('el-popover',{attrs:{"popper-class":_vm.popoverClass,"placement":"right","width":"350","disabled":!_vm.showPopover,"trigger":"click"},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"anchor-list",class:{
                    'dark': _vm.isDark
                }},[_c('p',{staticClass:"text-center text-white font-12 p-t-10 p-b-10 font-400 font-regular"},[_vm._v(_vm._s(_vm.match.anchor_number)+"名主播正在播本场比赛")]),_c('div',{staticClass:"ul-box p-b-15 p-l-20 p-r-20 flex flex-column overflow-y-auto"},[_vm._l((_vm.match.anchor_list),function(anchor){return [_c('AnchorInfo',{key:anchor.room_id,staticClass:"m-t-15 p-t-5 p-b-5 anchor-row",attrs:{"anchor":anchor,"dark":_vm.isDark},on:{"closePopover":_vm.closePopover}})]})],2),(!_vm.isDark)?_c('div',{staticClass:"animation-live w-100 flex align-center justify-center"},[_c('div',{staticClass:"button flex pointer align-center justify-center",on:{"click":_vm.goToVirtual}},[_c('IconPng',{staticClass:"pointer",attrs:{"width":22,"height":15,"icon":"score/animation-live"}}),_c('span',{staticClass:"font-12 m-l-5 font-500"},[_vm._v("观看动画直播")])],1)]):_vm._e()]),(_vm.showButton)?[_c('div',{staticClass:"living-buttons flex align-center bg-no-repeat bg-size-auto",attrs:{"slot":"reference"},slot:"reference"},[(_vm.showAnchor)?[_c('div',{staticClass:"flex align-center",on:{"click":_vm.goToLive}},[_vm._t("default")],2)]:[(_vm.showVirtualButton)?_c('IconPng',{staticClass:"pointer",attrs:{"width":27,"height":20,"icon":"score/live"},nativeOn:{"click":function($event){return _vm.goToLive.apply(null, arguments)}}}):_c('span',{staticClass:"button pointer w-100 d-inline-block font-12 text-center font-300 font-regular text-white",on:{"click":_vm.goToLive}},[_vm._v("直播中")])]],2)]:_c('div',{staticClass:"hosts font-12 text-14 pointer bg-no-repeat",class:{
                    'visibility-hidden': _vm.match.anchor_number <= 0,
                    'is-in-home': _vm.isHome ,
                    'is-text-living p-l-10': _vm.textLiving,
                    'p-l-20': !_vm.textLiving
                },attrs:{"slot":"reference"},slot:"reference"},[(_vm.isHome)?[(_vm.match.anchor_number === 1)?_c('span',{staticClass:"is-living d-inline-block font-12 text-gradient",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goToLive.apply(null, arguments)}}},[_vm._v(" 直播中 ")]):_c('span',{staticClass:"is-living d-inline-block font-12 text-gradient"},[_vm._v(" 直播中 ")])]:[(_vm.textLiving)?[(_vm.match.anchor_number === 1)?_c('span',{staticClass:"text-living d-inline-block font-14 font-400",on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goToLive.apply(null, arguments)}}},[_vm._v(" 直播中 ")]):_c('span',{staticClass:"text-living d-inline-block font-14 font-400"},[_vm._v(" 直播中 ")])]:[(_vm.match.anchor_number === 1)?[_c('span',{on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.goToLive.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.match.anchor_number)+" 位主播 ")])]:_c('span',{staticClass:"multi-host d-inline-block"},[_vm._v(" "+_vm._s(_vm.match.anchor_number)+" 位主播 ")])]]],2)],2)]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }