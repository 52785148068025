var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anchor-info flex justify-between align-center",class:{
        'dark': _vm.dark
    }},[_c('div',{staticClass:"user-info flex align-center",class:{
        'has-middle': _vm.showMiddle
      }},[_c('span',{staticClass:"user-icon d-inline-block border-radius-50 bg-no-repeat bg-size-cover bg-center",style:({
                backgroundImage: `url(${_vm.anchor.img})`
            })}),_c('div',{staticClass:"flex flex-column m-l-5 text-box"},[_c('CustomSpan',{staticClass:"font-12",class:{
                            'text-white': _vm.dark,
                            'text-14': !_vm.dark
                        },attrs:{"content":_vm.anchor.anchor_name || _vm.anchor.nick}}),_c('CustomSpan',{staticClass:"scale-9",class:{
                            'text-gray2': !_vm.dark,
                            'text-ccc': _vm.dark
                        },attrs:{"content":_vm.anchor.room_title}})],1)]),(_vm.showMiddle)?_c('div',{},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"operation flex align-center"},[_c('span',{staticClass:"icon d-inline-block bg-center bg-no-repeat bg-size-cover"}),_c('span',{staticClass:"enter-room font-12 p-l-5 p-r-5 text-center pointer",on:{"click":_vm.goToLiveRoom}},[_vm._v(" 进入直播间 ")])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"middle-icon d-inline-block pointer p-l-20 p-r-10"},[_c('span',{staticClass:"font-12"},[_vm._v("直播中")])])
}]

export { render, staticRenderFns }